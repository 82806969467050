var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('v-container', [_c('div', {
    staticClass: "d-lg-flex align-center"
  }, [_c('v-img', {
    staticClass: "w-100 mr-lg-56 mb-20 mb-lg-0",
    attrs: {
      "src": "/images/ci.svg",
      "contain": "",
      "max-width": "146"
    }
  }), _c('div', [_c('p', {
    staticClass: "mb-lg-14"
  }, [_vm._v(" Minart Frame"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" 103, Yangno-ro, Bibong-myeon, Hwaseong-si, Gyeonggi-do 18284 South Korea"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" CEO, Eunhong Min ")]), _c('p', [_vm._v(" Business Registration Number: 108-05-82640"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" +82-31-465-9088"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" +82-31-465-9098 (fax)"), _c('span', {
    staticClass: "d-none d-lg-inline-block text-center",
    staticStyle: {
      "width": "42px"
    }
  }, [_vm._v("|")]), _c('br', {
    staticClass: "d-block d-lg-none"
  }), _vm._v(" minart@minart.com ")])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }