var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "quicks"
  }, [_c('li', {
    staticClass: "quick"
  }, [_c('a', {
    staticClass: "quick__btn",
    attrs: {
      "href": "http://talk.naver.com/w45glt",
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "quick__btn-icon",
    staticStyle: {
      "background-color": "#03CF5D"
    }
  }, [_c('i', {
    staticClass: "icon icon-naver"
  })]), _c('div', {
    staticClass: "quick__btn-txt"
  }, [_c('p', [_vm._v("Naver Talk")])])])]), _c('li', {
    staticClass: "quick"
  }, [_c('a', {
    staticClass: "quick__btn",
    attrs: {
      "href": "http://pf.kakao.com/_xnxkhixj",
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "quick__btn-icon",
    staticStyle: {
      "background-color": "#FFF313"
    }
  }, [_c('i', {
    staticClass: "icon icon-kakao"
  })]), _c('div', {
    staticClass: "quick__btn-txt"
  }, [_c('p', [_vm._v("Kakao Channel")])])])])]);

}]

export { render, staticRenderFns }