<template>
    <div v-if="className == 'gnb'" class="w-100 d-xl-flex justify-space-between">
        <ul class="gnb gnb__left">
            <template v-for="item in gnbs">
                <li v-if="item.children" :class="className+'__li'" :key="item.title">
                    <div v-if="isMobile" :class="className+'__link gnb__link--toggle'"><h2>{{item.title}}</h2></div>
                    <router-link v-else :to="item.path" :class="className+'__link gnb__link--toggle'"><h2>{{item.title}}</h2></router-link>
                    <ul :class="className+'__sub'">
                        <div v-if="isDesktop" :class="className+'__sub-tit'">
                            <div :class="className+'__sub-tit__inner'">
                                <router-link :to="item.path" :class="className+'__sub-tit-link'">{{item.title}}</router-link>
                            </div>
                        </div>
                        <li v-for="child in item.children" :key="child.title">
                            <a :href="child.path">
                                <span class="w-100 d-block ellip">{{child.title}}</span>
                            </a>
                            <!-- <ul v-if="isDesktop" :class="className+'__product'">
                                <li v-for="item in child.product" :key="item.title">
                                    <router-link :to="item.path" :class="className+'__product__link'">{{item.title}}</router-link>
                                </li>
                            </ul> -->
                        </li>
                        <li v-if="isMobile" class="gnb__sub-li--series-all">
                            <a :href="item.path" :class="className+'__series-all'">All Series</a>
                        </li>
                    </ul>
                </li>
            </template>
        </ul>
        <ul class="gnb gnb__right">
            <template v-for="item in gnbs">
                <li v-if="!item.children" :class="className+'__li'" :key="item.title">
                    <router-link :to="item.path" :class="className+'__link'"><h2>{{item.title}}</h2></router-link>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: {
        className : String,
        indexNum : String,
    },
    data: function(){
        return {
            window: {
                width: 0,
                height: 0,
            },
            gnbs : [
                {
                    title : "Product Information",
                    path : "/en/solution/led",
                    children : [
                    ]
                },
                // {
                //     title : "Non-lit Ad Solution",
                //     path : "/en/solution/non-lighting",
                //     children : [
                //     ]
                // },
                {
                    title : "Company",
                    path : "/en/about",
                },
                {
                    title : "Projects",
                    path : "/en/business",
                },
                {
                    title : "Contact Us",
                    path : "/en/inquiry",
                },
            ],
        };
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created: function(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted: function(){
        this.init();
    },
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        async init() {
            let ledCategories = await api.v1.auction.programs.gets({ headers: { sort: "priority" }, params: { show: true, code: "led", language: "en" }});
            let lightingCategories = await api.v1.auction.programs.gets({ headers: { sort: "priority" }, params: { show: true, code: "non-lighting", language: "en" }});

            let ledProducts = await api.v1.boards.gets({ params: { show: true, code: "led", language: "en" }});
            let lightingProducts = await api.v1.boards.gets({ params: { show: true, code: "non-lighting", language: "en" }});

            ledCategories = ledCategories.programs
            lightingCategories = lightingCategories.programs
            ledProducts = ledProducts.boards;
            lightingProducts = lightingProducts.boards;

            this.gnbs.forEach((el) => {
                if(el.title == "Product Information") {
                    ledCategories.forEach((led) => {
                        el.children.push({ path: `/en/series/product?category=${led.subject}` , product: [], title: led.subject });
                    });
                }
                // else if(el.title == "Non-lit Ad Solution") {
                //     lightingCategories.forEach(light => { 
                //         el.children.push({ path: `/en/series/product?category=${light.subject}`, product: [], title: light.subject });
                //     });
                // }
            })
        }
    }
};
</script>