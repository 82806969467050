<template>
    <footer class="footer">
        <v-container>
            <div class="d-lg-flex align-center">
                <v-img src="/images/ci.svg" contain max-width="146" class="w-100 mr-lg-56 mb-20 mb-lg-0"></v-img>
                <div>
                    <p class="mb-lg-14">
                        Minart Frame<span class="d-none d-lg-inline-block text-center" style="width: 42px;">|</span><br class="d-block d-lg-none">
                        103, Yangno-ro, Bibong-myeon, Hwaseong-si, Gyeonggi-do 18284 South Korea<span class="d-none d-lg-inline-block text-center" style="width: 42px;">|</span><br class="d-block d-lg-none">
                        CEO, Eunhong Min
                    </p>
                    <p>
                        Business Registration Number: 108-05-82640<span class="d-none d-lg-inline-block text-center" style="width: 42px;">|</span><br class="d-block d-lg-none">
                        +82-31-465-9088<span class="d-none d-lg-inline-block text-center" style="width: 42px;">|</span><br class="d-block d-lg-none">
                        +82-31-465-9098 (fax)<span class="d-none d-lg-inline-block text-center" style="width: 42px;">|</span><br class="d-block d-lg-none">
                        minart@minart.com
                    </p>
                </div>
            </div>
        </v-container>
    </footer>
</template>

<script>
import api from "@/api";

export default {
    components: {
    },
    data() {
        return {
            setting: null,
        };
    },
    computed: {
        info() {
            return this.setting?.footer?.info || [];
        },
        sns() {
            return (this.setting?.footer?.sns || []).filter(({ shows }) => shows);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { setting } = await api.v1.setting.get();
            this.setting = setting;
        },
    },
};
</script>

<style scoped>
.footer {
    border-top: 1px solid #ddd;
    background-color: #fff;
    color: #777;
    padding: 40px 0;
    font-size: 1.2rem;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .footer {
        padding: 80px 0;
        font-size: 1.6rem;
        line-height: 1;
    }
}
@media (min-width: 1200px) {
}
</style>
