<template>
    <ul class="quicks">
        <li class="quick">
            <a href="http://talk.naver.com/w45glt" target="_blank" class="quick__btn">
                <span class="quick__btn-icon" style="background-color: #03CF5D">
                    <i class="icon icon-naver"></i>
                </span>
                <div class="quick__btn-txt">
                    <p>Naver Talk</p>
                </div>
            </a>
        </li>
        <li class="quick">
            <a href="http://pf.kakao.com/_xnxkhixj" target="_blank" class="quick__btn">
                <span class="quick__btn-icon" style="background-color: #FFF313">
                    <i class="icon icon-kakao"></i>
                </span>
                <div class="quick__btn-txt">
                    <p>Kakao Channel</p>
                </div>
            </a>
        </li>
    </ul>
</template>

<script>

export default {
    components: {
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style scoped>
/*quick*/
.quicks{
    position: fixed;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    z-index: 5;
    opacity: 1;
    /* animation: Fade 0.6s 0.3s ease-in-out forwards; */
}

@keyframes Fade {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.quicks .quick:not(:last-child){
    margin-bottom: 12px;
}

.quick__btn{
    position: relative;
}

.quick__btn-icon{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.quick__btn-txt{
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 140px;
    height: 100%;
    padding: 11px 14px 11px 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.15s ease-out;
}

.quick__btn-txt p{
    width: 100%;
    height: 100%;
    border-radius: 18px;
    background-color: #ddd;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .quicks{
        right: 28px;
    }
    .quicks .quick:not(:last-child){
        margin-bottom: 20px;
    }
    .quick__btn:hover .quick__btn-txt{
        opacity: 1;
        visibility: visible;
    }
    
}
@media (min-width:1200px){
    .quick__btn-icon{
        width: 60px;
        height: 60px;
    }
}

</style>