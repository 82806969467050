<template>
    <header class="header header--fixed" v-scroll="fixedScroll">
        <!-- S: body -->
        <div class="header__body">
            <h1 class="header__logo">
                <router-link to="/en" title="Home"><span class="d-sr-only">Minart Frame</span></router-link>
            </h1>
            <div class="header__contents">
                <div class="header__gnb ">
                    <!-- S: Gnb -->
                    <client-gnb className="gnb"></client-gnb>
                    <!-- E: Gnb -->
                </div>
                <div class="user-menu--moblie w-50">
                    <router-link to="/" class="d-flex align-center pa-8">
                        <i class="icon icon-kor mr-10"></i>
                        KOR
                    </router-link>
                </div>
            </div>
            <div class="header__right d-none d-xl-flex">
                <v-btn rounded outlined color="secondary" to="/en/inquiry" class="v-btn--primary mr-16">Contact Us</v-btn>
                <div class="lang-wrap">
                    <button class="lang-btn">
                        <i class="icon icon-global mr-8"></i>
                        ENG
                    </button>
                    <ul class="lang">
                        <li class="on">
                            <router-link to="/en">
                                <i class="icon icon-eng mr-10"></i>
                                ENG
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/">
                                <i class="icon icon-kor mr-10"></i>
                                KOR
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div> 
        <!-- E: body -->
        <!-- S: hamburger -->
        <div class="hamburger-btn">
            <span></span>
        </div>
        <button class="depth2-colse-btn">
            <i class="icon icon-right-arrow-lg"></i>
        </button>
        <!-- E: hamburger -->
        <!-- <div class="header__bg"></div> -->
    </header>
</template>

<script>
import ClientGnb from "@/components/client/en/inc/client-gnb.vue";
import UserMenu from "@/components/client/en/inc/user-menu.vue";
import LocaleSelect from "@/components/client/en/inc/locale-select.vue";

export default {
    components: {
        ClientGnb,
        UserMenu,
        LocaleSelect,
    },
    data(){
        return {
            window: {
                width: 0,
                height: 0,
            },
            dialog: false,
        };
    },
    mounted() {
        window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작

        if(this.isDesktop){
            this.initPcEvent();
        }
        if(this.isMobile){
            this.initMobileEvent();
        }
    },
    methods: {
        fixedScroll: function() {
            var header = document.querySelector('.header');
            var scrollTop = window.scrollY || document.documentElement.scrollTop;

            if(scrollTop > 50){
                header.classList.add('scroll-active');
            }else {
                header.classList.remove('scroll-active');
            }
        },
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        // PC
        initPcEvent: function() {
            // gnb
            var lis = document.getElementsByClassName("gnb__li");
            for (var i = 0; i < lis.length; i++) {
                lis[i].addEventListener('mouseenter', gnbLiOver);
                lis[i].addEventListener('mouseleave', gnbLiOut);
            }

            // lang
            var lang = document.querySelector('.lang-wrap');
            lang.addEventListener('click', langBox);

            var gnb = document.querySelector('.gnb');
            gnb.addEventListener('mouseenter', gnbOver);
            gnb.addEventListener('mouseleave', gnbOut);
        },
        destroyPcEvent: function() {
            // gnb
            var lis = document.getElementsByClassName("gnb__li");
            for (var i = 0; i < lis.length; i++) {
                lis[i].removeEventListener('mouseenter', gnbLiOver);
                lis[i].removeEventListener('mouseleave', gnbLiOut);
            }

            var gnb = document.querySelector('.gnb');
            gnb.removeEventListener('mouseenter', gnbOver);
            gnb.removeEventListener('mouseleave', gnbOut);
            
            // lang
            var lang = document.querySelector('.lang-wrap');
            lang.removeEventListener('click', langBox);
            lang.classList.remove('active');
        },
        // Mobile
        initMobileEvent: function() {
            // hamburgerBtn
            var hamburgerBtn = document.querySelector('.hamburger-btn');
            hamburgerBtn.addEventListener('click', mobileMenuToggle);

            // gnb
            var gnbLinkToggle = document.querySelectorAll('.gnb__link--toggle');
            gnbLinkToggle.forEach(function (el) {
                el.addEventListener('click', toggleAccordion);
                el.addEventListener('click', mobileMenuActive);
            });

            // mobileGnbOff
            var depth2Close = document.querySelector('.depth2-colse-btn');
            depth2Close.addEventListener('click', mobileMenuOff);
        },
        destroyMobileEvent: function() {
            // hamburgerBtn
            var hamburgerBtn = document.querySelector('.hamburger-btn');
            hamburgerBtn.removeEventListener('click', mobileMenuToggle);

            // gnb
            var gnbLinkToggle = document.querySelectorAll('.gnb__link--toggle');
            gnbLinkToggle.forEach(function (el) {
                el.removeEventListener('click', toggleAccordion);
            });

            // mobileGnb
            var header = document.querySelector('.header');
            header.classList.remove('mobile-menu-active');
        },
    },
    computed: {
        isDesktop: function() {
            return this.window.width >= 1200;
        },
        isMobile: function() {
            return this.window.width < 1200;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
        if(this.isDesktop){
            this.destroyPcEvent();
        }
        if(this.isMobile){
            thid.destroyMobileEvent();
        }
    },
    watch: {
        isDesktop: function() {
            if(this.isDesktop){
                this.initPcEvent();
            }else{
                this.destroyPcEvent();
            }
        },
        isMobile: function() {
            if(this.isMobile){
                this.initMobileEvent();
            }else{
                this.destroyMobileEvent();
            }
        },
    },
};

// PC
function gnbLiOver(){
    var headerBg = document.querySelector('.header__bg');
    var gnbSub = this.querySelector('.gnb__sub');

    this.classList.add('active');
    if (gnbSub == null){
        headerBg.classList.add('none');
    }
};
function gnbLiOut(){
    var headerBg = document.querySelector('.header__bg');
    var gnbSub = this.querySelector('.gnb__sub');

    this.classList.remove('active');
    if (gnbSub == null){
        headerBg.classList.remove('none');
    }
};
function gnbOver(){
    var header = document.querySelector('.header');
    header.classList.add('menu-active');
};
function gnbOut(){
    var header = document.querySelector('.header');
    header.classList.remove('menu-active');
};
function langBox() {
    var lang = document.querySelector('.lang-wrap');
    lang.classList.toggle('active');
};

function mobileMenuActive() {
    var header = document.querySelector('.header');
    header.classList.add('mobile-menu-active');
};

function mobileMenuOff() {
    var header = document.querySelector('.header');
    var gnbLi = document.querySelector('.gnb__li');
    header.classList.remove('mobile-menu-active');
    gnbLi.classList.remove('active');
};

// Mobile
function mobileMenuToggle(){
    var HTML = document.querySelector('html');
    var header = document.querySelector('.header');
    var gnbLinkToggle = document.querySelectorAll('.gnb__link--toggle');

    header.classList.toggle('mobile-menu-on');
    // HTML.classList.toggle('hidden');

    if(header.classList.contains('mobile-menu-on')){
        gnbLinkToggle.forEach(function (el) {
            el.parentNode.classList.remove('active');
        })
    }
};
function toggleAccordion(el) {
    var gnbLinkToggle = document.querySelectorAll('.gnb__link--toggle');
    var targetLi = el.currentTarget.parentNode.classList;
    var target = el.currentTarget;

    el.preventDefault();
    if (targetLi.contains('active')) {
        targetLi.remove('active');
    }else {
        gnbLinkToggle.forEach(function (el) {
            el.parentNode.classList.remove('active');
        })
        targetLi.add('active');
    }
};
</script>